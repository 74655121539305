import React from "react";

const Services = () => {
  return (
    <section className="container">
      <div>
        <h2>Services!</h2>
        Read more about us at :
        <a href="https://www.abcgiutgasgka.org/about/">
          https://www.abchajgjdas.org/about/
        </a>
      </div>
    </section>
  );
};
export default Services;
